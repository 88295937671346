<template>
    <b-modal
        id="confirm-2"
        :title="$t('COMMON.DEL_CONFIRM')"
        ref="confirmModal"
    >
        <p class="my-1">
            {{ $t("COMMON.DEL_ITEM_WARNING", { item: delItem }) }}
        </p>
        <template v-slot:modal-footer>
            <b-button
                variant="outline-secondary"
                v-on:click="close()"
            >
                {{ $t("COMMON.CANCEL") }}
            </b-button>
            <b-button variant="danger" v-on:click="confirm">
                {{ $t("COMMON.DELETE") }}
            </b-button>
        </template>
    </b-modal>
</template>

<script>
export default {
    name: "ConfirmModal",
    props: {
        delItem: {
            type: String,
        },
    },
    methods: {
        close() {
          this.$refs.confirmModal.hide();
        },
        show() {
          this.$refs.confirmModal.show();
        },
        confirm() {
            this.$refs.confirmModal.hide();
            this.$emit('confirmAction');
        }
    }
}
</script>