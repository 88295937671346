<template>
  <div>
    <ConfirmModal ref="delConfirmModal" :delItem="delItem" @confirmAction="confirmDelAction"></ConfirmModal>
    <div class="row chatusers-page">
      <div class="col-lg-12">
        <KTPortlet v-bind:title="$t('MENU.CHAT')">
          <template v-slot:toolbar v-if="isChatDomainActive">
            <b-button
              v-if="canAdd"
              variant="primary"
              class="mr-4"
              @click="openAddUsersModal()"
            >
              {{ $t("COMMON.ADD") }}
            </b-button>
            <b-button
              @click="enableDisableUsers(true)"
              class="mr-4 info lighten-1 text-light"
              :disabled="!chatUserTable.selected.length"
            >
              {{ $t("COMMON.ENABLE") }}
            </b-button>
            <b-button
              @click="enableDisableUsers(false)"
              class="mr-4 orange lighten-1 text-light"
              :disabled="!chatUserTable.selected.length"
            >
              {{ $t("COMMON.DISABLE") }}
            </b-button>
            <b-button
              v-if="canRemove"
              variant="danger"
              class="mr-4"
              @click="deleteUsers()"
              :disabled="!chatUserTable.selected.length"
            >
              {{ $t("COMMON.DELETE") }}
            </b-button>
            <download-csv :data="chatUserTable.users">
              <b-button variant="success">
                {{ $t("USERS.EXPORT_RESULTS") }}
              </b-button>
            </download-csv>
          </template>
          <template v-slot:body>
            <div class="row table-filter">
              <div class="col-sm-6">
                <b-form-group
                  :label="$t('COMMON.SEL_DOMAIN')"
                  label-for="select-domain"
                >
                  <model-list-select :list="selectDomain.allDomains"
                    v-model="selectDomain.selectedDomain"
                    option-value="domain"
                    option-text="domain"
                    :placeholder="$t('COMMON.SEL_DOMAIN')"
                    @input="onChangeDomain"
                    id="select-domain">
                  </model-list-select>
                </b-form-group>
              </div>
              <div class="col-sm-6">
                <v-text-field
                  v-model="chatUserTable.search"
                  append-icon="search"
                  :label="$t('COMMON.SEARCH')"
                  class="cus-searchBox"
                  single-line
                  hide-details
                ></v-text-field>
              </div>
            </div>

            <div class="col-sm-6">
              <b-button
                v-if="!isChatDomainActive"
                variant="primary"
                @click="enableChatDomain()"
              >
                {{ $t("COMMON.ENABLE") }}
              </b-button>
            </div>

            <v-data-table
              v-model="chatUserTable.selected"
              v-if="isChatDomainActive"
              :headers="headers"
              :items="chatUserTable.users"
              :search="chatUserTable.search"
              :single-select="chatUserTable.singleSelect"
              :items-per-page="chatUserTable.itemPerPage"
              :server-items-length="chatUserTable.total"
              :options.sync="chatUserTable.pagination"
              show-select
              item-key="username"
              class="elevation-1"
              ref="chatUserTable"
              :footer-props="{
                showFirstLastPage: true,
                'items-per-page-options': [5, 10, 15, 25, 50, -1],
              }"
              :key="tablekey"
            >
              <template v-slot:body="{ items }">
                <tbody v-if="chatUserTable.nodata">
                  <tr>
                    <td colspan="7" class="text-center">
                      {{ $t("COMMON.NO_DATA") }}
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <template>
                    <tr
                      v-for="item in items"
                      :key="item.username"
                    >
                      <td>
                        <v-checkbox
                          v-model="chatUserTable.selected"
                          :value="item"
                          style="margin: 0px; padding: 0px"
                          class="cusTable-checkbox"
                          color="rgba(0,0,0,.54)"
                          hide-details
                        ></v-checkbox>
                      </td>
                      <td class="text-start">{{ item.username }}</td>
                      <td class="text-start">{{ item.status }}</td>
                      <!--<td class="text-start">{{ item.type }}</td>-->
                      <td class="text-start" v-if="item.isAdmin">
                        <v-chip color="blue" class="text-white">{{
                          $t("COMMON.ADMIN")
                        }}</v-chip>
                      </td>
                      <td class="text-start" v-else>
                        -
                      </td>
                      <td class="text-start">
                        <v-icon
                          medium
                          color="dark"
                          class="mr-1"
                          @click="showItemInfor(item)"
                          v-b-tooltip.hover
                          :title="$t('COMMON.EDIT')"
                          v-if="canAdd && item.zulip_admin"
                        >
                          edit
                        </v-icon>
                        <v-icon
                          medium
                          color="dark"
                          class="mr-1"
                          @click="showItemInfor(item)"
                          v-b-tooltip.hover
                          :title="$t('COMMON.EDIT')"
                          v-else-if="canAdd && !item.zulip_admin"
                        >
                          edit
                        </v-icon>
                        <v-icon
                          medium
                          color="dark"
                          class="mr-1"
                          @click="deleteUser(item)"
                          v-b-modal.confirm-2
                          v-b-tooltip.hover
                          :title="$t('COMMON.DELETE')"
                          v-if="canRemove"
                        >
                          delete
                        </v-icon>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </template>
            </v-data-table>

            <!-- ----------manage member modal start------------- -->
            <b-modal
              hide-footer
              id="allowForm"
              ref="allowForm"
              size="lg"
              :title="$t('USERS.ADD_USER')"
            >
              <b-container fluid>
                <b-tabs class="kt-hide-tabs">
                  <b-tab title="Internal" active>
                    <v-card>
                      <v-card-title>
                        <v-text-field
                          v-model="allowForm.inactiveUsersTable.search"
                          append-icon="mdi-magnify"
                          :label="$t('COMMON.SEARCH')"
                          class="cus-searchBox"
                          single-line
                          hide-details
                        ></v-text-field>
                      </v-card-title>
                      <v-data-table
                        v-model="allowForm.inactiveUsersTable.selected"
                        :headers="inactiveUsersTableHeaders"
                        :items="allowForm.inactiveUsersTable.users"
                        :search="allowForm.inactiveUsersTable.search"
                        :single-select="allowForm.inactiveUsersTable.singleSelect"
                        show-select
                        item-key="username"
                        class="elevation-1"
                        ref="allowForm"
                        :footer-props="{
                          showFirstLastPage: true,
                        }"
                      >
                      </v-data-table>
                    </v-card>
                    <b-row class="my-4" v-if="canAdd">
                      <b-col sm="3">
                        <b-button
                          variant="primary"
                          :disabled="!allowForm.inactiveUsersTable.selected.length"
                          @click="allowUsers()"
                        >
                          {{ $t("COMMON.ALLOW") }}
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-tab>
                  <b-tab title="External">
                    <b-row class="my-4">
                      <b-col sm="4">
                        <label for="ext_email">{{
                          $t("LISTS.EMAIL_ADDRESS")
                        }}</label>
                      </b-col>
                      <b-col sm="8">
                        <b-form-input
                          id="ext_email"
                          v-model="$v.externalUserForm.email.$model"
                          :state="validateState('email')"
                          :placeholder="$t('LISTS.EMAIL_ADDRESS')"
                          aria-describedby="input-ext_email-feedback"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-ext_email-feedback">
                          {{ $t("VALIDATION.EMAIL_FIELD") }}
                        </b-form-invalid-feedback>
                      </b-col>
                    </b-row>
                    <PasswordForm v-model="externalUserForm.password" />
                    <b-row class="my-4">
                      <b-col sm="4">
                        <label for="ext_name">{{
                          $t("COMMON.FULL_NAME")
                        }}</label>
                      </b-col>
                      <b-col sm="8">
                        <b-form-input
                          id="ext_name"
                          v-model="$v.externalUserForm.uname.$model"
                          :state="validateState('uname')"
                          :placeholder="$t('COMMON.FULL_NAME')"
                          aria-describedby="input-ext_name-feedback"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-ext_name-feedback">
                          {{ $t("VALIDATION.REQUIRED_FIELD") }}
                        </b-form-invalid-feedback>
                      </b-col>
                    </b-row>
                    <b-row class="my-4">
                      <b-col sm="4">
                        <label for="ext_admin">{{
                          $t("COMMON.ADMIN")
                        }}</label>
                      </b-col>
                      <b-col sm="8">
                        <b-form-select
                          v-model="externalUserForm.role.selected"
                          value-field="value"
                          text-field="text"
                          :options="externalUserForm.role.data"
                          id="ext_admin"
                        ></b-form-select>
                      </b-col>
                    </b-row>
                    <b-row  class="my-4">
                      <b-col class="text-right">
                        <b-button
                          variant="primary"
                          v-on:click="addExternalUser()"
                        >
                          {{ $t("COMMON.SAVE") }}
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-tab>
                </b-tabs>  
              </b-container>
            </b-modal>
            <!-- ----------end manage member modal------------- -->

            <!-- ----------edit member modal------------- -->
            <b-modal
              id="updateForm"
              ref="updateForm"
              size="lg"
              :title="CEmodalTitle"
            >
              <b-container fluid>
                <b-row class="my-4">
                  <b-col sm="4">
                    <label class="mb-0">{{ $t("COMMON.STATUS") }}</label>
                  </b-col>
                  <b-col sm="8">
                    <b-form-select
                      v-model="updateForm.zulipStatus.selected"
                      value-field="value"
                      text-field="name"
                      :options="zulipStatus"
                      :disabled="updateForm.zulipStatus.selected < 2"
                    ></b-form-select>
                  </b-col>
                </b-row>
                <b-row class="my-4">
                  <b-col sm="4">
                    <label class="mb-0">{{ $t("ZULIP.IS_ADMIN") }}</label>
                  </b-col>
                  <b-col sm="8">
                    <b-form-select
                      v-model="updateForm.zulipAdmin.selected"
                      value-field="value"
                      text-field="name"
                      :options="zulipAdmin"
                      :disabled="updateForm.zulipStatus.selected == 3 || updateForm.zulipStatus.selected == 0"
                    ></b-form-select>
                  </b-col>
                </b-row>
              </b-container>

              <template v-slot:modal-footer>
                <b-button variant="outline-secondary" v-on:click="modalClose('updateForm')">
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button
                  variant="primary"
                  v-on:click="updateItemInfor(false)"
                >
                  {{ $t("USERS.UPDATE_USER") }}
                </b-button>
              </template>

            </b-modal>
            <!-- ----------end edit member modal------------- -->

            <!-- ----------update external user modal------------- -->
            <b-modal
              id="updateExternalUserModal"
              ref="updateExternalUserModal"
              size="lg"
              :title="CEmodalTitle"
            >
              <b-container fluid>
                <b-row class="my-4">
                  <b-col sm="4">
                    <label class="mb-0">{{ $t("COMMON.STATUS") }}</label>
                  </b-col>
                  <b-col sm="8">
                    <b-form-select
                      v-model="externalUserForm.status.selected"
                      value-field="value"
                      text-field="text"
                      :options="externalUserForm.status.data"
                    ></b-form-select>
                  </b-col>
                </b-row>
                <b-row class="my-4">
                  <b-col sm="4">
                    <label for="ext_email">{{
                      $t("LISTS.EMAIL_ADDRESS")
                    }}</label>
                  </b-col>
                  <b-col sm="8">
                    <b-form-input
                      id="ext_email"
                      v-model="$v.externalUserForm.email.$model"
                      :state="validateState('email')"
                      :placeholder="$t('LISTS.EMAIL_ADDRESS')"
                      aria-describedby="input-ext_email-feedback"
                      disabled
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-ext_email-feedback">
                      {{ $t("VALIDATION.EMAIL_FIELD") }}
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>
                <PasswordForm v-model="externalUserForm.password" />
                <b-row class="my-4">
                  <b-col sm="4">
                    <label for="ext_name">{{
                      $t("COMMON.FULL_NAME")
                    }}</label>
                  </b-col>
                  <b-col sm="8">
                    <b-form-input
                      id="ext_name"
                      v-model="$v.externalUserForm.uname.$model"
                      :state="validateState('uname')"
                      :placeholder="$t('COMMON.FULL_NAME')"
                      aria-describedby="input-ext_name-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-ext_name-feedback">
                      {{ $t("VALIDATION.REQUIRED_FIELD") }}
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>
                <b-row class="my-4">
                  <b-col sm="4">
                    <label for="ext_admin">{{
                      $t("COMMON.ADMIN")
                    }}</label>
                  </b-col>
                  <b-col sm="8">
                    <b-form-select
                      v-model="externalUserForm.role.selected"
                      value-field="value"
                      text-field="text"
                      :options="externalUserForm.role.data"
                      id="ext_admin"
                    ></b-form-select>
                  </b-col>
                </b-row>
              </b-container>

              <template v-slot:modal-footer>
                <b-button variant="outline-secondary" v-on:click="modalClose('updateExternalUserModal')">
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button
                  variant="primary"
                  v-on:click="updateItemInfor(true)"
                >
                  {{ $t("USERS.UPDATE_USER") }}
                </b-button>
              </template>

            </b-modal>
            <!-- ----------end update external user modal------------- -->
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { validationMixin } from "vuelidate";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import PasswordForm from "@/views/partials/content/PasswordForm.vue";
import ConfirmModal from "@/views/partials/content/ConfirmModal.vue";
import { postRequest } from "@/api/request.js";
import { required, minLength, email } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "chatusers",
  components: {
    KTPortlet,
    PasswordForm,
    ConfirmModal
  },
  data() {
    return ({
      tablekey:'',
      selectDomain: {
        selectedDomain: {},
        isChatDomainActive: true,
        allDomains: [],
      },    
      chatUserTable: {
        singleSelect: false,
        nodata: true,
        search: "",
        users: [],
        selected: [],
        itemPerPage: 10,
        total: 0,
        pagination: {},
      },
      allowForm: {
        selectedDomain: "",
        inactiveUsersTable: {
          singleSelect: false,
          nodata: true,
          search: "",
          users: [],
          selected: [],
          itemPerPage: 10,
          total: 0,
          pagination: {},
        },
      },
      updateForm: {
        username: "",
        domain: "",
        zulipStatus: {
          selected: 0,
          data: [],
        },
        zulipAdmin: {
          selected: 0,
          data: [],
        }
      },
      externalUserForm: {
        email: "",
        password: "",
        uname: "",
        role: {
          selected: 0,
          data:  [
            { value: 0, text: this.$t("COMMON.NO") },
            { value: 1, text: this.$t("COMMON.YES") }
          ],
        },
        status: {
          selected: 2,
          data: [
            { value: 2, text: this.$t("COMMON.ENABLED") },
            { value: 3, text: this.$t("COMMON.DISABLED") }
          ],
        },
      },
      CEmodalTitle: "",
      ENABLE: 2,
      DISABLE: 3,
      delItem: '',
      delData: {
        username: '',
        isExternal: ''
      },
      isOneDel: true
    })
  },
  computed: {
    headers: function () {
      return [
        { text: this.$t("COMMON.USERNAME"), value: "username", sortable: true },
        { text: this.$t("COMMON.STATUS"), value: "status", sortable: true },
        /*{ text: this.$t("COMMON.TYPE"), value: "type", sortable: true },*/
        { text: this.$t("COMMON.ADMIN"), value: "isAdmin", sortable: false },
        { text: this.$t("COMMON.ACTION"), value: "action", sortable: false },
      ];
    },
    inactiveUsersTableHeaders: function () {
      return [
        { text: this.$t("COMMON.USERNAME"), value: "username", sortable: true },
      ];
    },
    isChatDomainActive: function () {
      return this.selectDomain.isChatDomainActive == 1 ? true: false ;
    },
    zulipStatus: function () {
      return [
        { value: 0, name: this.$t("COMMON.NOT_ALLOWED"), disabled: true },
        { value: 1, name: this.$t("COMMON.ALLOWED"), disabled: true },
        { value: 2, name: this.$t("COMMON.ACTIVE") },
        { value: 3, name: this.$t("COMMON.DISABLED") },
      ];
    },
    zulipAdmin: function () {
      return [
        { value: 0, name: this.$t("COMMON.NO") },
        { value: 1, name: this.$t("COMMON.YES") },
      ];
    },
    canAdd: function () {
      return true;
      //return this.hasPermission("chatusers", 5);
    },
    canEnable: function () {
      return true;
      //return this.hasPermission("chatusers", 6);
    },
    canRemove: function () {
      return true;
      //return this.hasPermission("chatusers", 7);
    },
    canImpersonate: function () {
      return true;
      //return this.hasPermission("chatusers", 8);
    },
  },
  created() {
    postRequest({
      action: "getAllDomains",
      token: localStorage.id_token,
    }).then((res) => {
      if (res) {
        if (!res.returncode) {
          res.returndata = [{ domain: res.returndata }];
        }
        this.selectDomain.allDomains = res.returndata;
        this.selectDomain.selectedDomain = res.returndata[0];
        this.getChatUsersForSelectedDomain(this.selectDomain.selectedDomain.domain);
      }
    });
  },
  validations: {
    externalUserForm: {
      email: {
        required,
        email
      },
      password: {
        required
      },
      uname: {
        required,
        minLength: minLength(2),
      },
      role: {
        required
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("MENU.CHAT") }]);
  },
  methods: {
    /* -----validation---- */
    validateState(name) {
      const { $dirty, $error } = this.$v.externalUserForm[name];
      return $dirty ? !$error : null;
    },
    modalClose(modalItem) {
      this.$refs[modalItem].hide();
    },    
    resetExternalUserForm() {
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.externalUserForm.email = "";
      this.externalUserForm.password = "";
      this.externalUserForm.uname = "";
      this.externalUserForm.role.selected = "";
      this.externalUserForm.status.selected = 2;
    },
    /* ---------domain level Zulip actions---------- */
    getChatDomainStatusRequest(domain){
      return postRequest({
        action: "getZULIPDomainStatus",
        token: localStorage.id_token,
        domain: domain,
      }).then((res) => {
        if (res.returncode) {
          this.selectDomain.isChatDomainActive = res.returndata.zulip_status;
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
        //console.log("loaded zulip status for " + domain + ": " + this.selectDomain.isChatDomainActive);
      });
    },
    enableChatDomain(){
      const domain = this.selectDomain.selectedDomain.domain;
      postRequest({
        action: "provisionZULIPDomain",
        token: localStorage.id_token,
        domain: domain,
      }).then((res) => {
        if (res.returncode) {
          this.selectDomain.isChatDomainActive = 1;
          this.makeToastVariant("success", res.returndata);
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },
    disableChatDomain(){
      const domain = this.selectDomain.selectedDomain.domain;
      postRequest({
        action: "disableZULIPDomain",
        token: localStorage.id_token,
        domain: domain,
      }).then((res) => {
        if (res.returncode) {
          this.selectDomain.isChatDomainActive = 0;
          this.makeToastVariant("success", res.returndata);
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },
    /* ---------get domain Zulip users---------- */
    getChatUsersReqest(offset, limit, sorting, slicing, domain) {
      this.chatUserTable.users = [];
      this.chatUserTable.selected = [];
      return postRequest({
        action: "getZULIPUsersStatus",
        token: localStorage.id_token,
        domain: domain,
        offset: offset,
        limit: limit,
        sorting: sorting,
        slicing: limit > 0 ? slicing : 0,
      }).then((res) => {
        if (res.returncode && res.returndata.length > 0) {
          this.chatUserTable.users = res.returndata;
          this.chatUserTable.total = res.returndata.length;
          this.chatUserTable.nodata = false;
        } else {
          this.chatUserTable.users = [];
          this.chatUserTable.total = 0;
          this.chatUserTable.nodata = true;
        }
        //console.log("loaded zulip users for domain " + domain);
        //console.log(this.chatUserTable);

        this.chatUserTable.users.forEach((item) => {
          /*if (item.type == 2) {
            item.type = this.$t("ALIASES.EXTERNAL");
          } else {
            item.type = this.$t("ALIASES.INTERNAL");
          }*/

          if (item.zulip_status == 0) {
            item.status = this.$t("COMMON.NOT_ALLOWED");
          } else if (item.zulip_status == 1) {
            item.status = this.$t("COMMON.ALLOWED");
          } else if (item.zulip_status == 2){
            item.status = this.$t("COMMON.ENABLED");
          } else if (item.zulip_status == 3){
            item.status = this.$t("COMMON.DISABLED");
          }

          if (item.zulip_admin == 1) {
            item.isAdmin = true;
          } else {
            item.isAdmin = false;
          }
        });
      });
    },
    async getChatUsersForSelectedDomain(selectedDomain) {
      await this.getChatDomainStatusRequest(selectedDomain);
      if (this.selectDomain.isChatDomainActive == 1) {
          await this.getChatUsersReqest(
            0,
            this.chatUserTable.itemPerPage,
            0,
            1,
            selectedDomain
          ); 
        }
    },
    /* ---------end domain level Zulip actions---------- */

    /* ------allow items---- */
    async openAddUsersModal() { 
      //this.resetForm();
      this.allowForm.inactiveUsersTable.selected = [];
      this.allowForm.selectedDomain = this.selectDomain.selectedDomain.domain;
      await postRequest({
        action: "getZULIPInactiveUsersStatus",
        token: localStorage.id_token,
        domain: this.allowForm.selectedDomain,
      }).then((res) => {
        if (res.returncode && res.returndata.length > 0) {
          this.allowForm.inactiveUsersTable.users = res.returndata;
          this.allowForm.inactiveUsersTable.total = res.returndata.length;
          this.allowForm.inactiveUsersTable.nodata = false;
        } else {
          this.allowForm.inactiveUsersTable.users = [];
          this.allowForm.inactiveUsersTable.total = 0;
          this.allowForm.inactiveUsersTable.nodata = true;
        }
      });

      this.$refs["allowForm"].show();
    },
    allowUserRequest(domain, username) {
      //console.log("allowZULIPUser " + username + "@" + domain);
      return postRequest({
        action: "allowZULIPUser",
        token: localStorage.id_token,
        domain: domain,
        username: username,
      }).then((res) => {
        if (res.returncode) {
          this.makeToastVariant("success", res.returndata);
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
        //console.log("allowed " + username + "@" + domain + ", " + res.returndata);
      });
    },
    allowUsers() {
      if (this.allowForm.inactiveUsersTable.selected.length > 0) {
        const domain = this.allowForm.selectedDomain;
        const users = this.allowForm.inactiveUsersTable.selected;
        
        Promise.all(
          users.map((el) => {
            return this.allowUserRequest(domain, el.username);
          })
        ).then(() => {
          //console.log("finished allowing all");
          this.getChatUsersForSelectedDomain(this.selectDomain.selectedDomain.domain);
          this.modalClose("allowForm");
        });//.catch(console.log.bind(console)); // <-- this is badass
      }
    },
    /*------end allow items--------------*/

    /* ------edit items---- */
    showItemInfor(item) {
      //this.resetForm();
      if (!item.external) {
        postRequest({
          action: "getZULIPUserStatus",
          token: localStorage.id_token,
          domain: item.domain,
          username: item.username,
        }).then((res) => {
          if (res.returncode) {
            this.updateForm.username = item.username;
            this.updateForm.domain = item.domain;
            this.updateForm.zulipStatus.selected = res.returndata.zulip_status;
            this.updateForm.zulipAdmin.selected = res.returndata.zulip_admin;
  
            this.CEmodalTitle =
              this.$t("ZULIP.UPDATE") + ": " +
                item.username + "@" + item.domain;
  
            this.$refs["updateForm"].show();
          } else {
            this.makeToastVariant("danger", res.returndata);
          }
        });
      } else {
        postRequest({
          action: "getExternalZULIPUserStatus",
          token: localStorage.id_token,
          domain: item.domain,
          email: item.username,
        }).then((res) => {
          if (res.returncode) {
            this.CEmodalTitle =
            this.$t("ZULIP.UPDATE") + ": " +
              item.username;
            this.externalUserForm.email = item.username;
            this.externalUserForm.password = "";
            this.externalUserForm.uname = res.returndata.uname;
            if (item.isAdmin) {
              this.externalUserForm.role.selected = 1;
            } else {
              this.externalUserForm.role.selected = 0;
            }
            if (item.status == 'Active') {
              this.externalUserForm.status.selected = 2;
            } else {
              this.externalUserForm.status.selected = 3;
            }
            this.$refs["updateExternalUserModal"].show();
          } else {
            this.makeToastVariant("danger", res.returndata);
          }
        });
      }
    },

    updateItemInfor(isExternal) {
      if (!isExternal) {
        const status = this.updateForm.zulipStatus.selected;
        const role = this.updateForm.zulipAdmin.selected;
        const username = this.updateForm.username;
        const domain = this.updateForm.domain;
        postRequest({
          action: "updateZULIPUser",
          token: localStorage.id_token,
          domain: domain,
          username: username,
          status: status,
          role: role,
        }).then((res) => {
          if (res.returncode) {
            this.makeToastVariant("success", res.returndata);
            this.modalClose("updateForm");
          } else {
            this.makeToastVariant("danger", res.returndata);
          }
          this.getChatUsersForSelectedDomain(this.selectDomain.selectedDomain.domain);
        });
      } else {
        const domain = this.selectDomain.selectedDomain.domain;
        postRequest({
          action: "updateExternalZULIPUser",
          token: localStorage.id_token,
          domain: domain,
          email: this.externalUserForm.email,
          password: this.externalUserForm.password,
          uname: this.externalUserForm.uname,
          role: this.externalUserForm.role.selected,
          status: this.externalUserForm.status.selected,
        }).then((res) => {
          this.modalClose("updateExternalUserModal");
          this.resetExternalUserForm();
          if (res.returncode) {
            this.makeToastVariant("success", res.returndata);
          } else {
            this.makeToastVariant("danger", res.returndata);
          }
          this.getChatUsersForSelectedDomain(domain);
        });
      }
    },
    /* ------end edit items---- */

    /*------delete items--------------*/
    deleteUserRequest(username, isExternal) {
      const domain = this.selectDomain.selectedDomain.domain;
      if (!isExternal) {
        return postRequest({
          action: "deleteZULIPUser",
          token: localStorage.id_token,
          domain: domain,
          username: username,
        }).then((res) => {
          if (res.returncode) {
            this.makeToastVariant("success", res.returndata);
          } else {
            this.makeToastVariant("danger", res.returndata);
          }
        });
      } else {
        return postRequest({
          action: "deleteExternalZULIPUser",
          token: localStorage.id_token,
          domain: domain,
          email: username,
        }).then((res) => {
          if (res.returncode) {
            this.makeToastVariant("success", res.returndata);
          } else {
            this.makeToastVariant("danger", res.returndata);
          }
        });
      }
    },
    deleteUsers() {
      if (this.chatUserTable.selected.length > 0) {
        this.delItem = 'the selected items';
        this.$refs.delConfirmModal.show();
        this.isOneDel = false;
      }
    },
    deleteUser(el) {
      this.isOneDel = true;
      this.delItem = el.username;
      this.$refs.delConfirmModal.show();
      this.delData.isExternal = el.external;
      this.delData.username = el.username;
    },

    async confirmDelAction() {
      const domain = this.selectDomain.selectedDomain.domain;
      if (this.isOneDel) {
        const username = this.delData.username;
        const isExternal = this.delData.isExternal;
        await this.deleteUserRequest(username, isExternal);
        this.getChatUsersForSelectedDomain(domain);
      } else {
        const users = this.chatUserTable.selected;
        Promise.all(
          users.map((el) => {
            return this.deleteUserRequest(el.username, el.external);
          })
        ).then(() => {
          this.getChatUsersForSelectedDomain(
            domain
          );
        });//.catch(console.log.bind(console)); // <-- this is badass
      }
    },
    /*------end delete item--------------*/

    /*------update status enabled/disabled item--------------*/
    updateStatusUserRequest(domain, username, status, isExternal) {
      let action = null;
      if (!isExternal) {
        if (status == this.ENABLE) {
          action = "enableZULIPUser";
        } else if (status == this.DISABLE) {
          action = "disableZULIPUser";
        }
        if (action) {
          return postRequest({
            action: action,
            token: localStorage.id_token,
            domain: domain,
            username: username,
          }).then((res) => {
            if (res.returncode) {
              this.makeToastVariant("success", res.returndata);
            } else {
              this.makeToastVariant("danger", this.$t("ZULIP.ERROR_STATUS") + ": " + res.returndata);
            }
          });
        }
      } else {
        if (status == this.ENABLE) {
          action = "enableExternalZULIPUser";
        } else if (status == this.DISABLE) {
          action = "disableExternalZULIPUser";
        }
        if (action) {
          return postRequest({
            action: action,
            token: localStorage.id_token,
            domain: domain,
            email: username,
          }).then((res) => {
            if (res.returncode) {
              this.makeToastVariant("success", res.returndata);
            } else {
              this.makeToastVariant("danger", this.$t("ZULIP.ERROR_STATUS") + ": " + res.returndata);
            }
          });
        }
      }
    },

    enableDisableUsers(isEnable) {
      if (this.chatUserTable.selected.length > 0) {
        const domain = this.selectDomain.selectedDomain.domain;
        const users = this.chatUserTable.selected;
        let makeEnable = this.ENABLE;
        if (!isEnable) {
          makeEnable = this.DISABLE;
        }
        Promise.all(
          users.map((user) => {
            if (user.zulip_status != makeEnable) {
              return this.updateStatusUserRequest(
                domain,
                user.username,
                makeEnable,
                user.external
              ).then(async () => {
                await this.getChatUsersForSelectedDomain(domain);
                this.tablekey = new Date();
              });
            }
          })
        );
      }
    },
    /*------end update status--------------*/

    /* ----notify---- */
    makeToastVariant(variant = null, msg) {
      let title = this.$t("COMMON.ERROR");
      if (variant == "success") {
        title = this.$t("COMMON.SUCCESS");
      }
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        toaster: "b-toaster-top-center",
        // autoHideDelay: 5000,
        solid: true,
      });
    },
    /* ---- change domain dropdown ---- */
    onChangeDomain(newValue) {
      this.getChatUsersForSelectedDomain(newValue.domain);
    },
    /* ---- add external user ---- */
    addExternalUser() {
      this.$v.externalUserForm.$touch();
      if (this.$v.externalUserForm.$anyError) {
        return;
      }
      const domain = this.selectDomain.selectedDomain.domain;
      postRequest({
        action: "addExternalZULIPUser",
        token: localStorage.id_token,
        domain: domain,
        email: this.externalUserForm.email,
        password: this.externalUserForm.password,
        uname: this.externalUserForm.uname,
        role: this.externalUserForm.role.selected,
      }).then((res) => {
        this.modalClose("allowForm");
        this.resetExternalUserForm();
        if (res.returncode) {
          this.makeToastVariant("success", res.returndata);
          this.getChatUsersForSelectedDomain(this.selectDomain.selectedDomain.domain);
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    }
  },
};
</script>
