<template>
    <b-row class="my-4">
        <b-col sm="4">
            <label for="password" class="mb-0">
                {{ $t("COMMON.PASSWORD") }}
            </label>
            <p class="user-password-edit">
                <span @click="newPassword()">
                    {{ $t("COMMON.NEW") }}
                </span>&nbsp;|&nbsp;
                <span @click="cancelPassword()">
                    {{ $t("COMMON.CANCEL") }}
                </span>&nbsp;|&nbsp;
                <span @click="copyPassword('password')">
                    {{ $t("COMMON.COPY") }}
                </span>
            </p>
            <!-- <p class="user-pass-dec">
                {{ $t("USERS.PASS_DEC") }}
            </p> -->
        </b-col>
        <b-col sm="8">
            <b-form-input id="password" ref="password" v-model="password" :placeholder="$t('COMMON.PASSWORD')"
                @input="checkPassword" @change="checkPassword" @focus="isShowPassDesc = true" :state="valid_password"></b-form-input>
            <ul class="ml-5 my-2" v-show="isShowPassDesc">
                <li v-bind:class="{
                    is_validPass: contains_eight_characters,
                }">
                    {{ $t("VALIDATION.MIN_LETTERS", { num: 8 }) }}
                </li>
                <li v-bind:class="{ is_validPass: contains_lowercase }">
                    {{ $t("VALIDATION.LOWERCASE_LETTER") }}
                </li>
                <li v-bind:class="{ is_validPass: contains_uppercase }">
                    {{ $t("VALIDATION.UPPERCASE_LETTER") }}
                </li>
                <li v-bind:class="{ is_validPass: contains_number }">
                    {{ $t("VALIDATION.NUMBER_LETTER") }}
                </li>
                <li v-bind:class="{
                    is_validPass: contains_special_character,
                }">
                    {{ $t("VALIDATION.SPECIAL_LETTER") }}
                </li>
            </ul>
        </b-col>
    </b-row>
</template>

<script>
import { postRequest } from "@/api/request.js";
export default {
    name: "PasswordForm",
    props: {
        value: {
            required: true,
        },
    },
    data() {
        return ({
            password: "",
            contains_eight_characters: false,
            contains_uppercase: false,
            contains_lowercase: false,
            contains_number: false,
            contains_special_character: false,
            valid_password: null,
            isShowPassDesc: false,
        })
    },
    watch: {
        value(val) {
            this.password = val;
        }
    },
    methods: {
        /* password system */
        newPassword() {
            postRequest({
                action: "getRandomPassword",
                token: localStorage.id_token,
            }).then((res) => {
                if (res.returncode) {
                    this.password = res.returndata;
                    this.valid_password = true;
                    this.contains_eight_characters = true;
                    this.contains_special_character = true;
                    this.contains_uppercase = true;
                    this.contains_number = true;
                    this.contains_lowercase = true;
                    this.$emit('input', res.returndata);
                }
            });
        },
        cancelPassword() {
            this.password = "";
            this.contains_eight_characters = false;
            this.contains_special_character = false;
            this.contains_uppercase = false;
            this.contains_number = false;
            this.contains_lowercase = false;
            this.valid_password = false;
        },
        copyPassword(id) {
            var copyText = document.getElementById(id);
            copyText.select();
            document.execCommand("copy");
            window.getSelection().removeAllRanges();
        },
        checkPassword() {
            this.password_length = this.password.length;
            const format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

            if (this.password_length >= 8) {
                this.contains_eight_characters = true;
            } else {
                this.contains_eight_characters = false;
            }
            this.contains_number = /\d/.test(this.password);
            this.contains_uppercase = /[A-Z]/.test(this.password);
            this.contains_lowercase = /[a-z]/.test(this.password);
            this.contains_special_character = format.test(this.password);

            if (
                this.contains_eight_characters === true &&
                this.contains_special_character === true &&
                this.contains_uppercase === true &&
                this.contains_number === true &&
                this.contains_lowercase === true
            ) {
                this.valid_password = true;
                this.$emit('input', this.password);
            } else {
                this.valid_password = false;
                this.$emit('input', "");
            }
        },
    }

}
</script>